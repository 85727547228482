[ng-cloak]{ display:none;}
[ui-view]{ min-height: 50vh;}
#Shopify-Affiliate-App{
	min-height: 60vh;
	padding-bottom: 40px;
}


.loading-animation{
	&.bar{
	  position: relative;
	  width: 100%;
	  height: 2px;
	  background-color: white;
		.litem {
		  content: "";
		  display: inline;
		  position: absolute;
		  width: 0;
		  height: 100%;
		  left: 0%;
		  text-align: center;
		}
		.litem:nth-child(1) {
		  background-color: gray;
		  animation: loading 3s linear infinite;
		}
		.litem:nth-child(2) {
		  background-color: black;
		  animation: loading 3s linear 1s infinite;
		}
		.litem:nth-child(3) {
		  background-color: white;
		  animation: loading 3s linear 2s infinite;
		}

	}
}
@keyframes loading {
    from {left: 0%; width: 0;z-index:100;}
    33.3333% {left: 0; width: 100%;z-index: 10;}
    to {left: 0; width: 100%;}
}


li.active a {
	text-decoration: none;
	color: white;
}


.hide{ display: none; }

#code-box{
	position: relative;
	#btn-copy-code{
		position: absolute;
		top:0;right:0;
	}
}


[data-target]{ cursor: pointer;}



.code-preview a img {
    max-width: 100%;
    height: auto;
}



	.modal.full-screen .modal-dialog {
	width: 70%;
	height: 70%;
	}
	.modal.full-screen .modal-content {
	height: 100%;
	height: auto;
	border-radius: 0;
	}
	.modal.full-screen .modal-content img{
		margin:0px auto;
	}
	.full-screen-button{
	position: relative;
	max-width:100px;
	max-height:100px;
	height:100px;
	background: #eaeaea;
	}
	/*
	.full-screen-button.no-image{
	width:100px;height:
	}
	*/
	.full-screen-button button{
	position: absolute;
	top:0;
	right:0;
	}
	.full-screen-button img{
	max-height: 100px;
	margin:0px auto;
	}
	.code{
	position: relative;
	}
	.copy-button{
	position: absolute;
	top:0;
	right:0;
	}

.list-group .list-group-item a{
	display: block;
}



pre{
	color:#06c0f8;
	white-space: pre-wrap;
	padding:10px;
	border:1px solid #3a3a3a;
	background:black;
}


.mtop-10{ margin-top:10px;}
.mtop-20{ margin-top:20px;}
.mtop-30{ margin-top:30px;}
.mtop-40{ margin-top:40px;}
.mtop-50{ margin-top:50px;}
.mtop-60{ margin-top:60px;}



@brand-primary:         darken(#428bca, 6.5%); // #337ab7
@brand-success:         #5cb85c;
@brand-info:            #5bc0de;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;


.header-row td{ 		
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: rgba(245,245,245,0.5) .5px 1px ;
}

.header-row { 		
		background-color:#dadada !important;
}


/** LOGIN REGISTER COMPONENT **/

#login-component-not-logged-in{
	min-height: 60vh;
}
table input[type="text"] {
  border-radius: 0px;
  padding: 0px;
  margin: 0px;
}